<template>
    <div class="container mt-5">
        <div v-if="mobile && isLogged" class="hover-blue cursor-pointer col-md-7 d-flex align-items-start align-items-md-center mb-4 px-2" @click="goBack">
            <div class="me-3 mt-2 mt-md-0">
                <icon-arrow :size="25" style="transform: rotate(180deg);" :color="'var(--dark-gray-200)'" />
            </div>
            <div class="d-flex flex-column align-items-start ms-1">
                <h6 class="title-22 color-blue-700 mb-1">{{$t("Informativo")}}</h6>
                <div class="d-flex justify-content-end">
                    <label class="mb-2 subtitle-15"  id="credit">{{$t("nueva funcionalidad")}}
                    </label>
                </div>
            </div>
        </div>
        <lottie :options="defaultOptions" :height="300"/>
        <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                <icon-no-product  :size="150" class="icon-position"/>
        </div>
        <div class="d-flex flex-column justify-content-center align-items-center mt-5">
            <h2 class="title-26">{{ this.isLogged ? 'Lo sentimos, vuelva más tarde, estamos trabajando en esta nueva funcionalidad.' : 'Lo sentimos, su sesión no está iniciada, intente iniciar sesión.'}}</h2>
        </div>
        <main-button v-if="mobile && !isLogged" icon :title="'layout.account.log_in'" :id="'buy'"  @click="window.NeedToLoginEvent.postMessage(JSON.stringify({isLogged: false}));" style="width: 140px; border-radius: 20px;" />
    </div>
</template>
<script>
import Lottie from 'vue-lottie';
import MainButton from '../../../common/components/buttons/MainButton.vue';
import IconNoProduct from '../../products/assets/svg/iconNoProduct.vue';
import iconArrow from '../../../common/svg/iconArrow.vue';
import { mapGetters } from 'vuex';

export default {
    name: "Infoworks",
    components: {
        Lottie,
        IconNoProduct,
        iconArrow,
        MainButton
    },
    computed: {
        ...mapGetters({
            mobile: "common/mobile",
            isLogged: "auth/isLogged",
        }),
    },
    data(){
        return {
            // defaultOptions: { animationData: animationData, loop: true },
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1)
        },
    }
}
</script>

<style scoped>
.icon-position{
    margin-top: -271px;
}

@media (max-width: 475px) {
        .icon-position {
            margin-top: -220px;
            margin-left: 5px;
        }
    }
</style>